<script setup>
  import { Analytics } from '@vercel/analytics/vue';
</script>
<template>
  <Analytics />
	<!-- <img :src="noise" ref="overlay" alt="" class="grain"> -->
	<!-- <svg viewBox='0 0 372 372' ref="noise" xmlns='http://www.w3.org/2000/svg' class="grainSvg">
		<filter id='noiseFilter'>
			<feTurbulence 
			type='fractalNoise' 
			:baseFrequency= freq
			numOctaves='10' 
			stitchTiles='noStitch'/>
		</filter>
		
		<rect width='100%' height='100%' filter='url(#noiseFilter)'/>
		</svg> -->
	<div class="frameSVG" v-if="isFireFox">
		<svg xmlns='http://www.w3.org/2000/svg'>
			<filter id="filter">
				<feGaussianBlur stdDeviation="12" />
			</filter>
			
			<mask id="mask">
				<rect x='1vw' y='4vh' rx='50' width="98vw" height="92vh" filter="url(#filter)" fill="white"/>
			</mask>
			
		</svg>
	</div>
	
	<div class="page-holder" ref="pageHolder">
		<router-view v-slot="{ Component }">
			<transition @before-enter="onBeforeEnter" @enter="onEnter" @after-enter="onAfterEnter"
				@before-leave="onBeforeLeave" @leave="onLeave" @after-leave="onAfterLeave">
				<component :is="Component" ref="routerEl" />
			</transition>
		</router-view>
		<LogoComp ref="logo" />
	</div>

</template>

<script>
import LogoComp from '@/components/LogoComp'
import noise from '@/assets/images/noise.jpg'
import gsap from 'gsap'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import store from '@/assets/js/store'
import E from '@/assets/js/utils/E'
import GlobalEvents from '@/assets/js/utils/GlobalEvents'
export default {
  name: 'App',
  components: {
    LogoComp
  },
  data () {
    return {
		freq: 0,
		tempFreq: 0,
		tick: 0,
		noise: noise,
		angle: 0,
		offset: Math.PI * 0.1,
		radius: window.innerWidth * 3,
		outAngle: 0,
		inAngle: 0, 
		logoAngle: 0,
		durationTransition: 0,
		activeComp: '',
		isTransitionning: false,
		scaleVal: 0,
		rafSlower: 0,
		isFireFox: store.isFireFox,
		isSafari: store.isSafari
	}
  },
  watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = to.meta.title || 'GroomLake';
            }
        },
    },
  created () {
	this.route = useRoute()
	this.router = useRouter()
	console.log('FIREFOX', this.isFireFox)
	if(this.isFireFox){
		document.documentElement.classList.add('isFireFox')
	}
	if(this.isSafari){
		document.documentElement.classList.add('isSafari')
	}
  },
  mounted(){

	document.documentElement.style.setProperty('--scaleX', 1)
	document.documentElement.style.setProperty('--scaleY', 1)
	document.documentElement.style.setProperty('--TX', 0 + 'px')


	this.$refs.pageHolder.style.setProperty('--backgroundImage', noise)
	const vh = store.window.h * 0.01
	document.documentElement.style.setProperty('--vh', `${vh}px`)

	E.on(GlobalEvents.RESIZE, this.onResize)
	if(store.window.h > store.window.w){
		this.offset = Math.PI * 0.2
	}
	this.$refs.pageHolder.style.setProperty('--radius', -this.radius+'px')

	store.RAFCollection.add(this.onRaf, 0)
	this.updateFreq()
	
	this.$refs.pageHolder.style.setProperty('--radius', -this.radius+'px')


	this.router.beforeEach((to, from, next) => {
		this.isTransitionning = true
		if(!from.name){
			this.durationTransition = 0
			// E.emit('transitionEnd')
		} else {
			this.durationTransition = 2
		}
		this.timeline = gsap.timeline()
		this.timeline.to(this, { scaleVal: 1, onUpdate:() => {
			this.$refs.pageHolder.style.setProperty('--scaleVal',this.scaleVal )
		}, onComplete: () => {
			next()
		}})
	})

  },
  methods: {
	updateFreq(){
		this.freq =  2 + this.getRandomInt(2, 10) * 0.1
	},
	getRandomInt(min, max) {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	},

	setPositions(){
	},
	onRaf(){
		if(this.isFireFox) return
		this.rafSlower++
		this.rafSlower = this.rafSlower % 5
		if(this.rafSlower === 0){
			if(!this.isTransitionning){
				this.freq += (this.tempFreq - this.freq) * 0.1
				if(this.freq - this.tempFreq < 0.1){
					this.tempFreq = this.getRandomInt(2, 10)
				}
				this.$refs.pageHolder.style.setProperty('--bright', 1 + this.freq * 0.02)
			}
		}
	
	},
	onBeforeEnter(el){
		gsap.set(el, { x: this.radius * Math.cos(-this.offset * 2), y: this.radius * Math.sin( this.offset * 2), rotate: (this.offset * 2) * 180 / Math.PI})

		this.logoAngle = this.offset
		this.$refs.logo.$el.style.transform = `translate3D(${this.radius * Math.cos(this.logoAngle)}px, ${this.radius * Math.sin(this.logoAngle)}px, 0) rotate(${ (this.logoAngle) * 180 / Math.PI}deg)`
	},
	onEnter(el, done){
		this.inAngle = this.offset * 2

		gsap.to(this, {logoAngle: -this.offset, duration: this.durationTransition, ease: 'power2.inOut', onUpdate: () => {
			this.$refs.logo.$el.style.transform = `translate3D(${this.radius * Math.cos(this.logoAngle )}px, ${this.radius * Math.sin(this.logoAngle )}px, 0) rotate(${ (this.logoAngle) * 180 / Math.PI}deg)`

			el.style.transform = `translate3D(${this.radius * Math.cos(this.logoAngle + this.offset )}px, ${this.radius * Math.sin(this.logoAngle + this.offset )}px, 0) rotate(${ (this.logoAngle + this.offset) * 180 / Math.PI}deg)`
		}, onComplete: () => {
			done()
		}})
	},
	onAfterEnter(el){
		gsap.to(this, {
			scaleVal: 0,
			ease: 'power2.out',
			duration: 1,
			onUpdate:() => {
				this.$refs.pageHolder.style.setProperty('--scaleVal',this.scaleVal )
			},
			onComplete: () => {
				this.isTransitionning = false
				this.$refs.logo.$el.style.transform = `translate3D(${this.radius * Math.cos(this.offset)}px, ${this.radius * Math.sin(this.offset)}px, 0) rotate(${ (this.offset) * 180 / Math.PI}deg)`
				E.emit('transitionEnd')
			}
		})

		this.activeComp = el
		this.onResize()
	},
	onBeforeLeave(el){
		gsap.set(el, { x: this.radius * Math.cos(0), y: this.radius * Math.sin(0), rotate: 0})
	},
	onLeave(el, done){
		this.outAngle = 0
		gsap.to(this, { outAngle: -this.offset * 2, duration: this.durationTransition, ease: 'power2.inOut', onUpdate: () => {
			el.style.transform = `translate3D(${this.radius * Math.cos(this.outAngle)}px, ${this.radius * Math.sin(this.outAngle)}px, 0) rotate(${ (this.outAngle ) * 180 / Math.PI}deg)`
		}, onComplete: () => {
			done()
		}})
	},
	onAfterLeave(){
	},
	onResize(){
		const vh = store.window.h * 0.01
		document.documentElement.style.setProperty('--vh', `${vh}px`)

		if(store.window.h > store.window.w){
			this.offset = Math.PI * 0.1
			this.radius = window.innerWidth * 7
		} else {
			this.offset =  Math.PI * 0.08
			this.radius = window.innerWidth * 4
		}
		this.$refs.pageHolder.style.setProperty('--radius', -this.radius+'px')
		this.activeComp.style.transform = `translate3D(${this.radius * Math.cos(0)}px, ${this.radius * Math.sin(0)}px, 0) rotate(${ (0) * 180 / Math.PI}deg)`
	}
  }

}
</script>

<style lang="less">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	//   text-align: center;
	//   color: #2c3e50;
	// margin-top: 60px;
	font-smooth: never;

	.box {
			width: 100vw;
			height: calc(var(--vh, 1vh) * 100);
			position: fixed;
			z-index: 100;
			top: 0;
			pointer-events: none;

			svg {
				display: block;
				width: 100vw;
				height: calc(var(--vh, 1vh) * 100);
				border-radius: 20px;
				// overflow: visible;

				rect {
					// stroke: white;
					fill:white;
					// stroke-dasharray: 8px;
					// stroke-width: 1px;
				}

			}
		}


	.grain {
		z-index: 100;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		mix-blend-mode: soft-light;
		pointer-events: none;
		opacity: 1;
		width: 100vw;
		height: calc(var(--vh, 1vh) * 100);

	}

	.page-holder {
		--scaleVal: 0;
		transform: perspective(500px) translate3d(var(--radius), 0, calc(-100px * var(--scaleVal)));
		filter: blur(calc(var(--scaleVal) * var(--scaleVal) * 8px));
		transform-origin: 50vw 50vh;
		position: absolute;
		top: 0;
		background-color: #171717;
		--bright: 1;
		will-change: filter;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s ease;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
}
</style>
